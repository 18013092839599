.services-menu {
  background: white;
  border-radius: 7px;
  max-width: 304px;
}

.services-menu ul {
  list-style: none;
  padding: 0;

  li {
    cursor: pointer;
    width: 247px;
    height: 28px;
    border-radius: 3px 0 0 3px;

    &::before {
      content: "\2022";
      color: #59bf90;
      font-weight: bold;
      display: inline-block;
      line-height: 1;
      margin: 0 15px;
      vertical-align: bottom;
      font-size: 25px;
    }
  }

  li:hover, .active-list-item, .active-list-item:hover {
    &::before {
      color: #2e2f76;
    }

    &::after {
      content: '';
      display: block;
      position: relative;
      bottom: 25px;
      left: 247px;
      width: 5px;
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
    }
  }

  li:hover {
    background-color: rgba(62, 64, 149, 0.2);

    &::after {
      border-left: 14px solid rgba(62, 64, 149, 0.2);
    }
  }

  .active-list-item, .active-list-item:hover {
    background-color: rgba(62, 64, 149, 0.4);

    &::after {
      border-left: 14px solid rgba(62, 64, 149, 0.4);
    }
  }
}

.services-image {
  width: 452px;
  max-width: 100%;
  height: auto;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 18px 32px 0 rgba(0, 0, 0, 0.35);
}
