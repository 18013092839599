@import '../../Styles/variables';

.ourValues-section {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &-item {
    color: #212529;
    text-align: center;
    padding: 4rem 3rem 1rem 3rem;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  p {
    text-align: center;
    color: #212529;
  }

  @media screen and (max-width: $ayu-bp-sm) {
    padding-top: 6rem;
  }

}

.values-title {
  padding: 1rem;
  color: #3e4095;
  width: 100%;
  text-align: center;
}