@import './variables';
.Enviar {
  width: 137px;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
}

.back-button {
  color: $blueberry;
  &:hover {
    color: #28295f;
  }
}