@import '../../Styles/variables';
.contact-input-small {
  width: 271px;
  height: 36px;
  border: 0;
  background-color: #ffffff;
}

.contact-input-large {
  max-width: 413px;
  height: 112px;
  background-color: #ffffff;
  resize: none;
}

.line {
  height: 329px;
  border-right: solid #eaebf2;
  border-width: 2px;
}

.send-button {
  width: 143px;
  height: 36px;
  border: 0;
  border-radius: 7px;
}

.mail-icon {
  font-size: 1.3em;
}

.contact-info {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  /*max-height: 36vh;
  overflow:scroll;*/

  @media screen and (max-width: $ayu-bp-md) {
    line-height: 2;
    max-height: none;
  }

}