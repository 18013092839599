.landing-form {

    &__wrapper {
        border-radius: 4px;
        border: solid 1px #cccccc;
        overflow: hidden;
        margin-bottom: calc(#{$spacer} * 0.6875);
    }

    .clearfix {
        border-bottom: solid 1px #cccccc;
    }

    .clearfix:last-child {
        border-bottom: none;
    }

    .clearfix>div {
        border-right: 1px solid #cccccc;
    }

    .clearfix>div:last-child {
        border: none;
    }

    .form-control,
    .input-group-addon {
        border: 1px solid transparent;
        font-size: $font-size-sm;
    }

    .form-control {
        border-bottom: 1px solid transparent;
        box-shadow: none !important;
        padding-right: 0.3125rem;
    }

    .input-group-addon {
        background: transparent;
    }
}

.input-group {
    border-radius: 3px;
    background-color: $white;
}

.form-control {
    border-color: black;
}

.input-group-btn {
    border: 1px solid;

    .btn {
        border: none;
    }
}

.date-control {
    max-width: 300px;
}

.search-control {
    .input-group-addon {
        background: transparent;
        border: 1px solid;
    }

    input::-ms-clear {
        display: none;
    }
}

input::-ms-reveal {
    display: none;
}

.switch input {
    display: none;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #DFDFDF;
    background-color: #FFF;
    -webkit-transition: .4s;
    transition: .4s;
    box-shadow: 0px 0px 0px 0px #CCC inset;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;

}

input:checked+.slider {
    background-color: #64BD63;
    border-color: #64bd63;

    &.slider-alert {
        background-color: #D8232D;
    }
}

input:focus+.slider {
    box-shadow: 0 0 1px #64BD63;

    &.slider-alert {
        box-shadow: 0 0 1px #D8232D;
    }
}

input:checked+.slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 22px;
}

.slider.round:before {
    border-radius: 50%;
}

select {
    font-size: 20px;
    background-color: $white;
    padding: 1rem
}

.file-select {
    position: relative;
    text-decoration: underline;

    input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        height: 100%;
        width: 100%
    }
}

.no-resize {
    resize: none;
}


.custom-control-indicator {
    width: 13px;
    height: 13px;
    border: 1px solid #CCC;
    background-color: #FFF !important;

    &:after {
        display: block;
        position: absolute;
        background: #16569e;
        width: 7px;
        height: 7px;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        border-radius: 50%;
    }
}

.custom-radio .custom-control-input:checked~.custom-control-indicator:after {
    content: '';
}

.custom-control-input:focus~.custom-control-indicator {
    box-shadow: none;
}