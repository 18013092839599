@import '../../../Styles/variables';
.doc-container-header {
  height: 40px;
  border: 1px solid lightslategray;
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 1px;
  background-color: rgba($aqua-blue, 0.5);
  font-weight: bold;
  font-size: 1rem;
  &:hover{
    background-color: rgba($aqua-blue, 0.25);
  }
  span {
    height: 100%;
  }
}

.doc-container {
  border: 1px solid lightslategray;
  border-radius: 0 5px 5px 5px;
  background-color: $white;
}

.doc-clickable {
  cursor: pointer;
  color: $blueberry;
  &:hover{
    color: rgba($blueberry, 0.7);
    background-color: rgba($blueberry, 0.25);
  }
}

.tab-active {
  background-color: $aqua-blue;
  color: $white
  span {
    color: $white
  }
}

.comment-list {
  max-height: 200px;
  margin-bottom: 10px;
  overflow:scroll;
}

.tab-parent {
  padding-left: 2px;
  padding-right: 2px;
}

.tab-parent:first-child {
  padding-left: 0;
  padding-right: 3px;
}

.tab-parent:last-child {
  padding-right: 0;
  padding-left: 3px;
}