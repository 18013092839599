.footer {
  background-color: $blueberry;
  width: 100%;
}

.footer-title {
  font-size: $font-size;
}

//------------ Mobile 375px Aliados View Start ---------- //

@media (max-width: 411px) {

  .logo2 {
    top: 10px;
  }

  .logo3 {
    top: 0px;
  }

  .logo4 {
    left: 22px;
    top: 20px;
  }

  .logo5 {
    top: 31px;
    left: 50px;
  }

}

//------------ Mobile 375px Aliados View End ---------- //

//------------ Mobile 425px Aliados View Start ---------- //

@media (min-width: 414px) and (max-width: 425px) {

  .logo2 {
    top: 9px;
  }

  .logo3 {
    top: 7px;
  }

  .logo4 {
    left: 31px;
    top: 38px;
  }

  .logo5 {
    top: 31px;
    left: 66px;
  }

}

//------------ Mobile 425px Aliados View End ---------- //

//------------ Tablet Aliados View Start ---------- //

@media (min-width: 768px) and (max-width: 1024px){

  .logo1{
    top: 36px;
  }

  .logo2 {
    top: 48px;
  }

  .logo3 {
    top: 21px;
    left: 64px;
  }

  .logo4 {
    left: 47px;
    top: 44px;
  }

  .logo5 {
    top: 45px;
    left: 103px;
  }

}

//------------ Mobile Aliados View End ---------- //


//------------ Mobile Aliados View End ---------- //

