@import '../../Styles/variables';

.fix-search {
  /*    position: absolute;
      bottom: -100px;
      left: calc(50% - 426.5px);
      width: 853px;
    @media screen and (max-width: $ayu-bp-md) {
      left: calc(50% - 325px);
      width: 650px;
    }
    @media screen and (max-width: $ayu-bp-sm) {
      left: calc(50% - 175px);
      width: 350px;
      bottom: -200px;
    }

    @media screen and (max-width: 375px) {
      left: calc(50% - 160px);

      width: 325px;
    }

    */
  margin-bottom: 4rem;
}

.info-section {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &-item {
    color: #4a4a4a;
    text-align: center;
    padding: 1rem 3rem;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    &:hover {
      color: $aqua-blue;
      text-decoration: none;
    }
  }

  p {
    text-align: center;
  }

  @media screen and (max-width: $ayu-bp-sm) {
    padding-top: 6rem;
  }

}