@import "variables";
.card-property {
  width: 320px;
  height: 270px;

  border-radius: 17px;
  background-color: $white;

  &-name {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.25rem;
    background: linear-gradient(to bottom,rgba($blueberry, 0.1),rgba($blueberry, 1));
    font-size: $font-size-md;
    color: $white;
    font-weight: bold;
  }

  &-image-container {
    height: 169px;
    position: relative;
    width: 100%;
    border-radius: 17px;
  }

  &-image {
    background-size: cover;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;


  }

  &-body {
    h3 {
      font-size: 18px;
    }
  }
}

.card-property-text {
  font-size: $font-size-sm;
  color: $greyish-brown;
  position: absolute;
  bottom: 8px;
  left: 20px;
}

.card-search {
  padding: 1rem;
  border-radius: 7px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 18px 32px 0 rgba(0, 0, 0, 0.35);
  background-color: #e4e4e4;
  z-index: 2222;
}

.card-login {
  width: 304px;
  height: 277px;
  border-radius: 7px;
  background-color: $white;
}