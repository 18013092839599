$fa-font-path : '../node_modules/font-awesome/fonts';

$aqua-blue: #00c4e5;
$blueberry: #3e4095;
$ice-blue: #eaf0f5;
$greyish-brown:#4a4a4a;
$brown-grey-two:#9b9b9b;
$white: #fff;
$white-two: #f8f8f8;
$gray: #888888;
$ibf-color-res: #E7E7E7;

$font-size-sm: .9rem; // 13px;
$font-size: 1rem; // 14px;
$font-size-md: 1.125rem; // 16px;
$font-size-lg: 1.5rem; // 24px;

$ayu-bp-xl: 1200px;
$ayu-bp-lg: 992px;
$ayu-bp-md: 768px;
$ayu-bp-sm: 576px;
$ayu-bp: 576px;

$theme-colors: (primary: $blueberry,
secondary: $aqua-blue);
// FONTS FAMILY
$font-family-base: "Exo 2", sans-serif;
