.logos>* {
  margin-right: 0.5rem;
}

.main-container {
  margin-top: 132px;
}

.landing-header {
  background-image: url('https://sbxcloud.com/www/ayuraweb/Images/familia-ayura.png');
  background-position: center;

  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 65vh;
  position: relative;
  margin-bottom: 1rem;
}

@media (max-width: 425px) {
  .main-container {
    margin-top: 100px;
  }
}

.ayu-background {
  background-image: linear-gradient(to top, rgba(238, 238, 238, 0), #dbdbdb);
  background-size:cover;
  background-repeat: no-repeat;
}

body {
  font-size: 14px;
}

.clickable {
  cursor: pointer;
}