.about-us-card {
  padding: 12px;
  border-radius: 7px;
  background-color: rgba(53, 55, 129, 0.1);
}

.about-us-card-text {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
}

.about-us-image {
  width: 436px;
  max-width: 100%;
  height: auto;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 18px 32px 0 rgba(0, 0, 0, 0.35);
}