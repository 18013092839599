.form-control {
    color: $greyish-brown;
    padding: 10px 15px;
    border: 0;
}

.doc-uploader {
  border: 1px dashed lightslategray;
  padding: 1rem;
  border-radius: 5px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}