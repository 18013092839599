.repair-detail-content {
  margin-top: 20px;
  padding: 10px 20px;
  background: white;
}

.repair-detail-content table {
  margin-top: 20px;
}

.repair-detail-content table td {
  width: 35%;
}

.repair-detail-content table td:nth-child(1) {
  width: 15%;
}

.repair-detail-content table td:nth-child(2) {
  width: 50%;
}

.repair-detail-content img {
  width: 25%;
  max-width: 285px;
}