.text-ayu {
  &-bold {
    font-weight: bold;
  }
}

.text-ayu-description {
  font-size: $font-size-md;
  color: $gray;
}

.text-ayu-size {
  font-size: $font-size;
  line-height: $font-size;

  &-sm {
    font-size: $font-size-sm;
    line-height: $font-size-sm;
  }

  &-md {
    font-size: $font-size-md;
    line-height: $font-size-md;
  }

  &-lg {
    font-size: $font-size-lg;
    line-height: $font-size-lg;
  }
}