.row.repair-thumbs .col-12:nth-child(1n) {
  margin-bottom: 20px;
  margin-top: 20px;
}

.row.repair-thumbs > .col-12 > .row {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.row.repair-thumbs > div.col-12 > div.row {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}