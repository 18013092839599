    .fab {
        width: 70px;
        height: 70px;
        background-image: url("https://sbxcloud.com/www/ayuraweb/Images/chat.png");
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        transition: all 0.1s ease-in-out;
        font-size: 50px;
        color: white;
        text-align: center;
        line-height: 70px;
        position: fixed;
        z-index: 999;
        right: 50px;
        bottom: 50px;
    }

    .fab:hover {
        box-shadow: 0 6px 14px 0 #666;
    }
