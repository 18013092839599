.ayura-main-navbar {
  height: 121px;
  background-color: #3e4095;
  margin-top: 53px;
  font-size: $font-size
}

.navbar-toggler {
  cursor: pointer;
  border: none;
}

.navbar-toggler:focus {
  outline: none;
}

.nav-link {
  text-align: center;
}

.ayura-logo {
  height: 150px;
}

.ayura-slogan {
  margin-left: 1rem;
  animation: pulse 5s ease-in-out;
}

@media (max-width: 425px) {
  .ayura-main-navbar {
    height: inherit;
    margin-top: 73px;
  }

  .ayura-logo {
    height: 50px;
  }
}

@keyframes pulse {
  0% {
    color: $aqua-blue;
  }
  50% {
    color: rgba(255,255,255,0.3);
  }
  100% {
    color: white;
  }
}

.container-pse-logo{
  margin-right: 100px;
  margin-top: 40px;
  position: relative;
}

.pse-logo{
  height: 89px;
  width: 235px;
  position: fixed;
}

@media (max-width: 816px) {
  .container-pse-logo{
    margin-right: 75px;
    margin-top: 11px;
  }

  .pse-logo{
    height: 61px;
    width: 171px;
  }
}