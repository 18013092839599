@import url('https://fonts.googleapis.com/css?family=Exo+2');

@font-face {
    font-family: 'Exo 2', sans-serif;
    src: url('https://fonts.googleapis.com/css?family=Exo+2');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
}

.FTN{
  font-family: BlinkMacSystemFont;
}
