@import '../../Styles/variables';

.requirements-card {
  background: white;
  border-radius: 7px;
  width: 340px;
  height: 158px;
  padding: 1.5rem;
  text-align: center;
  margin: auto;
  display: block;
}

.files-card {
  background-color: rgba(62, 64, 149, 0.2);
  height: 550px;
}

.no-padding {
  padding: 0;
}

.circle {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background: #00c4e5;
}

.circle-container {
  position: absolute;
  top: 330px;
  left: calc(50% - 40px);
  z-index: 100;

  @media screen and (max-width: $ayu-bp-md) {
    top: 415px;
    left: 175px;
  }

  @media screen and (max-width: $ayu-bp-sm) {
    left: 150px;
  }
}


.circle:before,
.circle:after {
  content: '';
  position: absolute;
  top: 25px;
  left: 25px;
  right: 25px;
  bottom: 25px;
}

.circle.plus:before,
.circle.plus:after {
  background: white;
  box-shadow: 1px 1px 1px #ffffff9e;
}

.circle.plus:before {
  width: 3px;
  margin: 3px auto;
}

.circle.plus:after {
  margin: auto 3px;
  height: 3px;
  box-shadow: none;
}