@import "../../../Styles/variables";

.info-table {
  background-color: $white;
  padding: 0.5rem 0.625rem 0.625rem;
  border: solid 1px #dddddd;

  &-header {
    background-color: $white;
    font-weight: bold;
    font-size: $font-size-md;
  }

  &-item {
    &:hover {
      background-color: $aqua-blue;
    }
  }
}

.item-active {
  background-color: $aqua-blue;
  color: $white
}

.info-table .bar {
  margin-left: -0.5rem;
  color: white;
  background-color: #00c4e5;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
